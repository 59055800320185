<template>
  <section>
    <div class="progress">
      <div>
        <p class="progress-text">Your progress:</p>
        <h3 class="header-3">{{ title[progress] }}</h3>
        <p style="width: 310px">{{ text[progress] }}</p>
        <hr class="divider" />
        <p class="next-target">
          <span>Next Up: {{ nextStep }}</span>
        </p>
        <SimpleNextButton @click="handleNext()" label="Continue" />
      </div>
      <div class="house-block">
        <Vue3Lottie
          loop="false"
          v-if="progress === 0 && house === 1"
          class="house-block"
          :animation-data="House1Step1"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 1 && house === 1"
          class="house-block"
          :animation-data="House1Step2"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 2 && house === 1"
          class="house-block"
          :animation-data="House1Step3"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 3 && house === 1"
          class="house-block"
          :animation-data="House1Step4"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 4 && house === 1"
          class="house-block"
          :animation-data="House1Step5"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 5 && house === 1"
          class="house-block"
          :animation-data="House1Step6"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 6 && house === 1"
          class="house-block"
          :animation-data="House1Step7"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 7 && house === 1"
          class="house-block"
          :animation-data="House1Step8"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 0 && house === 2"
          class="house-block"
          :animation-data="House2Step1"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 1 && house === 2"
          class="house-block"
          :animation-data="House2Step2"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 2 && house === 2"
          class="house-block"
          :animation-data="House2Step3"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 3 && house === 2"
          class="house-block"
          :animation-data="House2Step4"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 4 && house === 2"
          class="house-block"
          :animation-data="House2Step5"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 5 && house === 2"
          class="house-block"
          :animation-data="House2Step6"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 6 && house === 2"
          class="house-block"
          :animation-data="House2Step7"
        />
        <Vue3Lottie
          loop="false"
          v-else-if="progress === 7 && house === 2"
          class="house-block"
          :animation-data="House2Step8"
        />
        <Vue3Lottie
          loop="false"
          v-else
          class="house-block"
          :animation-data="House1Step1"
        />
      </div>
    </div>
    <!-- <div class="quick-links">
      <div style="display: flex; align-items: flex-start; margin-bottom: 20px;">
        <img :src="lightBulb" alt="light bulb" />
        <h3 class="header-3" style="margin-left: 15px;">Quick Links</h3>
      </div>
      <div class="quick-links-inner">
        <div
          v-for="linkBlock in links"
          :key="linkBlock.title"
          class="links-block"
        >
          <h4 class="header-4">{{linkBlock.title}}</h4>
          <ExternalLink v-for="link in linkBlock.links" :key="link.name" :link="link" />
        </div>
      </div> -->
    <!-- </div> -->
  </section>
</template>

<script setup>
import progressTemplate from "../assets/images/progress-template.svg";
import SimpleNextButton from "@/components/ui/SimpleNextButton";
import lightBulb from "@/assets/icons/light bulb.svg";
import links from "@/data/quickLinks";
import ExternalLink from "@/components/ExternalLink";
import { Vue3Lottie } from "vue3-lottie";
import ChecklistAPI from "@/api/ChecklistAPI";
import SloganLetsBuild from "@/components/SloganLetsBuild";
import House1Step1 from "@/assets/animations/house1/step1.json";
import House1Step2 from "@/assets/animations/house1/step2.json";
import House1Step3 from "@/assets/animations/house1/step3.json";
import House1Step4 from "@/assets/animations/house1/step4.json";
import House1Step5 from "@/assets/animations/house1/step5.json";
import House1Step6 from "@/assets/animations/house1/step6.json";
import House1Step7 from "@/assets/animations/house1/step7.json";
import House1Step8 from "@/assets/animations/house1/step8.json";
import House2Step1 from "@/assets/animations/house2/step1.json";
import House2Step2 from "@/assets/animations/house2/step2.json";
import House2Step3 from "@/assets/animations/house2/step3.json";
import House2Step4 from "@/assets/animations/house2/step4.json";
import House2Step5 from "@/assets/animations/house2/step5.json";
import House2Step6 from "@/assets/animations/house2/step6.json";
import House2Step7 from "@/assets/animations/house2/step7.json";
import House2Step8 from "@/assets/animations/house2/step8.json";
import { onBeforeMount, ref, defineEmits, onMounted } from "vue";
import UserAPI from "@/api/UserAPI";

import { useRouter } from "vue-router";
import { months } from "moment";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const nextTaskId = ref();
const nextTaskCatgory = ref();
// const text = ref(null)
// const house1 = ref([
//   House1Step1,
//   House1Step2,
//   House1Step3,
//   House1Step4,
//   House1Step5,
//   House1Step6,
//   House1Step7,
//   House1Step8
// ])
// const house2 = ref([
//   House2Step1,
//   House2Step2,
//   House2Step3,
//   House2Step4,
//   House2Step5,
//   House2Step6,
//   House2Step7,
//   House2Step8
// ])
const step = ref();

const progress = ref(0);
const nextStep = ref();
const house = ref(1);
onMounted(async () => {
  const user = await store.state.user;

  const avatar = await UserAPI.getMyAvatar();
  if (!user.home_style) await router.push("/personalize/select-house");
  else if (avatar.id < 1) await router.push("/personalize/avatar-builder");

  house.value = user?.home_style;

  const checklist = await await ChecklistAPI.getTrainee(user.id, user.role);
  let allTasks = [];
  checklist.forEach((item) => {
    const included = allTasks.find((task) => task.name === item.name);
    if (!included) allTasks.push(item);
    else {
      item?.tasks.forEach((task) => {
        included?.tasks.push(task);
      });
    }
    const thisTask = allTasks.find((task) => task.name === item.name);
    thisTask?.tasks?.sort(function (a, b) {
      return a.order - b.order;
    });
  });
  allTasks.forEach((item) => {
    const subs = [];
    item.tasks.forEach((task) => {
      const included = subs?.find((sub) => sub.name === task.name);
      if (!included) {
        subs.push(task);
      } else {
        task.subtasks?.forEach((sub) => {
          included.subtasks?.push(sub);
        });
        task?.progresses?.forEach((sub) => {
          included.progresses?.push(sub);
        });
      }
    });
    item.tasks = subs;
  });
  const taskList = [];
  allTasks.forEach((task) => {
    task.tasks.forEach((sub) => {
      taskList.push(sub);
    });
  });
  //  const allTasks =  workdayList?.tasks?.concat(badgingList?.tasks, technologyList?.tasks, logisticsList?.tasks, month1List?.tasks,  month2List?.tasks, month3List?.tasks, engagementList?.tasks)
  const totalLength = taskList?.length;
  taskList.forEach((task) => {
    const i = task.progresses?.findIndex((item) => item.task_id === task.id);
    if (task.progresses) task.status = task.progresses[i].status;
    else task.status = "available";
  });

  const nextIndex = await taskList.findIndex(
    (task) => task.status !== "completed" && task.status !== "in-review"
  );
  const category = await checklist.find(
    (category) => category.id === taskList[nextIndex]?.checklistID
  );

  const percentDone = Math.floor((nextIndex / totalLength) * 100);
  nextTaskId.value = taskList[nextIndex]?.id;
  nextStep.value = taskList[nextIndex]?.name;
  nextTaskCatgory.value = category;
  if (nextIndex < 0) progress.value = 7;
  else if (percentDone > 85) progress.value = 6;
  else if (percentDone > 70) progress.value = 5;
  else if (percentDone > 56) progress.value = 4;
  else if (percentDone > 43) progress.value = 3;
  else if (percentDone > 28) progress.value = 2;
  else if (percentDone > 13) progress.value = 1;
  else progress.value = 0;

  // if (house.value === 1) step.value = house1.value[progress.value]
  // if (house.value === 2) step.value = house2.value[progress.value]
  // console.log('step:', step.value)
  // console.log('pregress:', progress.value)
});

const emit = defineEmits({
  tabClick: (path) => typeof path === "string",
});
const getTasks = async () => {};
const handleNext = () => {
  const task = nextTaskId.value;
  const topic = nextTaskCatgory.value.name;
  const route = nextTaskCatgory.value.section;
  if (route === "hr") emit("tabClick", "/hr", "");
  else if (route === "training") {
    emit("tabClick", "/courses", "");
  } else if (route === "setting up")
    emit("tabClick", `/task`, `?task=${task}&topic=${topic}`);
};
const title = [
  "The Blueprint",
  "Site Preparation",
  "Laying the Foundation",
  "Frame it Up!",
  "Walls, Windows, and Doors",
  "Roof",
  "Decorating",
  "Finishing touches",
];
const text = [
  "Every great journey starts with a great plan! Complete your preliminary onboarding tasks to prepare for the rest of your build and your new Home Services role.",
  "Today’s preparation determines tomorrow’s achievement. Get your building site ready for success with the following tasks.",
  "A crack in the foundation could spell disaster. Focus on developing strong foundational knowledge and your structure will stand tall!",
  "The foundation is set and you’re ready to build! Complete the following tasks to build out the primary framing and superstructure of your home and gain fundamental knowledge related to your new position.",
  "Getting a tight seal on your home— and a firm grasp on your job responsibilities— is vital to any builder’s success. ",
  "What’s a home without a roof over your head?! Think of the roof as the protective cherry on top of your efforts thus far. Complete the following tasks to get closer to the end of your training and the completion of your virtual build!",
  "Make it your own! Complete the following tasks and add some personality to your home. ",
  "Ah, it’s truly the little things that make a house a home. Round out your skillset and workplace knowledge with these final tasks to complete your onboarding and your virtual build!",
];
</script>

<style scoped>
.progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding: 40px;
  border-radius: 20px;
  background: linear-gradient(176.8deg, #ff8217 14.16%, #ff9900 85.76%);
  font-size: 16px;
  color: white;
}

.progress-text {
  font-size: 14px;
  text-transform: uppercase;
  color: #383939;
}

.header-3 {
  margin: 0;
  font-family: "Cabin Sketch Bold", sans-serif;
  font-size: 36px;
}
.house-block {
  width: 550px;
}
.divider {
  width: 100%;
  height: 1px;
  margin: 50px 0 30px;
  border: 0;
  background: #ffa93f;
}

.next-target span {
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
}

.quick-links-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
}

.links-block {
  flex: 1 1 0;
  padding: 30px;
  border-radius: 20px;
  background: white;
}

.header-4 {
  margin-top: 0;
  font-family: "Helvetica Neue Lt Std", Arial, sans-serif;
  text-transform: uppercase;
  color: #ff8217;
}
</style>
